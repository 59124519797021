import React, { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";

import "./App.css";

function App() {
  const [siteText, setSiteText] = useState("");
  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.dcaywood.nrgfinder";
    } else if (isIOS) {
      console.log("iphone");
      window.location.href =
        "https://apps.apple.com/us/app/energy-finder/id1627816849";
    } else {
      setSiteText("Please Visit on a Mobile Device to go to the store");
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>{siteText}</p>
      </header>
    </div>
  );
}

export default App;
